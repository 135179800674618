/* eslint-disable prettier/prettier */
import { useSelector } from "react-redux";
import { useEffect } from "react";
import useFetchActiveUsersMetrics from "./useFetchActiveUsersMetrics";

const useGetActiveUsersMetrics = () => {
  const { fetchActiveUsersMetrics } = useFetchActiveUsersMetrics();
  const activeUsersMetrics = useSelector((state) => state.activeUsersMetrics);

  useEffect(() => {
    // Fetch immediately
    fetchActiveUsersMetrics();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  console.log("Active Users Metrics from Redux:", activeUsersMetrics);

  // Return the metrics from Redux, or default values if not available
  const metrics = {
    dailyActiveUsers: activeUsersMetrics?.dailyActiveUsers ?? 0,
    weeklyActiveUsers: activeUsersMetrics?.weeklyActiveUsers ?? 0,
    dailyActivePercentage: activeUsersMetrics?.dailyActivePercentage ?? 0,
    weeklyActivePercentage: activeUsersMetrics?.weeklyActivePercentage ?? 0,
  };

  console.log("Processed Active Users Metrics:", metrics);
  return metrics;
};

export default useGetActiveUsersMetrics;
