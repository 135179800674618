/* eslint-disable prettier/prettier */
import { useDispatch } from "react-redux";
import { fetchActiveUsersMetricsAction } from "store/actions/activeUsersMetrics/fetchActiveUsersMetricsAction";
import { toast } from "react-toastify";
import { useLogout } from "utils/hooks";
import { useCallback } from "react";

const useFetchActiveUsersMetrics = () => {
  const dispatch = useDispatch();
  const { logOut } = useLogout();

  const fetchActiveUsersMetrics = useCallback(() => {
    return new Promise((resolve, reject) => {
      fetch(`${process.env.REACT_APP_API_URL}`, {
        method: "POST",
        credentials: "include",
        body: JSON.stringify({
          query: `
            query {
              getActiveUsersMetrics {
                dailyActiveUsers
                weeklyActiveUsers
                dailyActivePercentage
                weeklyActivePercentage
              }
            }
          `,
        }),
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
      })
        .then(async (res) => {
          if (res.status !== 200 && res.status !== 201) {
            const error = await res.json();
            if (error.errors[0].message.includes("BRKN_6001")) {
              toast.warn(`For security purposes please log in again.`);
              logOut();
            } else {
              toast.error(`Hmm, something went wrong.`);
              reject(new Error("Failed!"));
            }
          }
          return res.json();
        })
        .then((resData) => {
          console.log("Active Users Metrics API Response:", resData);
          if (resData.errors) {
            toast.error(
              `Hmm, something went wrong. ${resData.errors[0].message}`
            );
            reject(new Error(resData.errors[0].message));
          } else if (resData.data && resData.data.getActiveUsersMetrics) {
            console.log(
              "Dispatching Active Users Metrics:",
              resData.data.getActiveUsersMetrics
            );
            dispatch(
              fetchActiveUsersMetricsAction(resData.data.getActiveUsersMetrics)
            );
            resolve(resData.data.getActiveUsersMetrics);
          } else {
            console.error("Invalid API response format:", resData);
            toast.error("Invalid response format from server");
            reject(new Error("Invalid response format from server"));
          }
        })
        .catch((err) => {
          console.error("Error fetching active users metrics:", err);
          reject(err);
        });
    });
  }, [dispatch, logOut]);

  return {
    fetchActiveUsersMetrics,
  };
};

export default useFetchActiveUsersMetrics;
